import * as React from "react"
import Template from "components/template";
import * as config from 'utils/configs/proxem';

// markup
const ProxemPage = () => {
  return (
    <Template config={config} />
  )
}

export default ProxemPage
